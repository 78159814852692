export const appConfig = {
    // PREFIX FOR CLIENT
    client_prefix: 'HLX-',
    // MEMBER REGISTRATION
    use_ssn_flag: false,
    use_dob_flag: true,
    // PROVIDER DIRECTORY
    use_provider_directory_api: true,
    // FHIR RESOURCES
    use_eob_member_responsibility: true,
    // MEMBER SEARCH FIELDS
    use_member_search_policy_number: true,
    // EOB CUSTOMIZATIONS
    use_eob_total: true,
    // ENABLE/DISABLE SSO FIELDS
    use_sso: false,
    // CLINICAL DATA    
    clinical_fhir_data: [
        {
            label: 'Allergies',
            resource: 'AllergyIntolerance',
            show: true,
        },
        {
            label: 'Care Plans',
            resource: 'CarePlan',
            show: true,
        },
        {
            label: 'Conditions',
            resource: 'Condition',
            show: true,
        },
        {
            label: 'Encounters',
            resource: 'Encounter',
            show: true,
        },
        {
            label: 'Immunizations',
            resource: 'Immunization',
            show: true,
        },
        {
            label: 'Medications',
            resource: 'MedicationRequest',
            show: true,
        },
        {
            label: 'Observations',
            resource: 'Observation',
            show: true,
        },
        {
            label: 'Procedures',
            resource: 'Procedure',
            show: true,
        }
    ],
    // AVAILABLE APPS
    available_apps_flexpa: true,
}